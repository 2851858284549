export const ROLE_USER = 3

export const USER_ROLES: any = {
  'admin@gmail.com': 0,
  'adminppkd@gmail.com': 2,
  'peserta@gmail.com': 3,
  'pengajar@gmail.com': 4,
  'admindinas@gmail.com': 5,
}

export const USER_ROLES_TO_ROUTE: any = {
  1: 'pengguna',
  2: 'dashboard',
  3: 'dashboard',
  4: 'pelatihan',
  5: 'materi',
}
