<template>
  <VaInnerLoading :loading="loadingImg">
    <VaImage
      :class="props.class + ` ${imageError ? 'rounded-full' : ''}`"
      :fit="props.fit"
      :src="imageError ? imageNotFound : props.src"
      @loaded="handleImgLoad"
      @error="imageError = true"
    />

    <!-- <div v-else class="rounded-xl bg-white">
      <VaImage class="w-" :src="imageNotFound" @loaded="handleImgLoad" />
    </div> -->
  </VaInnerLoading>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { imageNotFound } from '../utils/images'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  fit: {
    type: String,
    default: 'cover',
    required: true,
  },
  class: {
    type: String,
    default: 'w-full',
    required: true,
  },
})

const loadingImg = ref<boolean>(true)
const imageError = ref<boolean>(false)

// watch(
//   () => props.loading,
//   (newVal: boolean) => {
//     if (!newVal) loadingImg.value = false
//   },
// )

const handleImgLoad = () => {
  loadingImg.value = false
}
</script>

<style></style>
