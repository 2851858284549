import { getCookie, setCookie, removeCookie } from 'typescript-cookie'

export const setStorage = (data: any) => {
  const json = JSON.stringify(data)
  setCookie('session_data', btoa(json))
}

export const setToken = (token: any) => {
  setCookie('session_id', token)
}

export const getStorage = () => {
  if (!getCookie('session_data')) return ''

  let data
  if (getCookie('session_data')) data = JSON.parse(atob(getCookie('session_data') as string))

  return data
}

export const getToken = () => {
  let token
  if (getCookie('session_id')) token = getCookie('session_id')

  return token
}

export const removeStorage = () => {
  removeCookie('session_data')
}

export const removeToken = () => {
  removeCookie('session_id')
}
