// import { ROLE_USER } from '../../data/constants'
// import { getStorage } from '../../utils/auth'

export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export interface INavigationRouteByRole {
  [key: number]: INavigationRoute[]
}

// let roleId: any
// if (getStorage()) roleId = getStorage().role_id
// const role: number = Number(roleId)

const roleRoutes = {
  // admin super
  1: [
    {
      name: 'pengguna',
      displayName: 'Pengguna',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'pengguna_role',
      displayName: 'Role Pengguna',
      meta: {
        icon: 'badge',
      },
    },
    {
      name: 'ppkd_master',
      displayName: 'PPKD',
      meta: {
        icon: 'groups',
      },
    },
    {
      name: 'kejuruan',
      displayName: 'Kejuruan',
      meta: {
        icon: 'engineering',
      },
    },
    {
      name: 'pengumuman',
      displayName: 'Pengumuman',
      meta: {
        icon: 'campaign',
      },
    },
    {
      name: 'galeri',
      displayName: 'Galeri',
      meta: {
        icon: 'photo_library',
      },
    },
    {
      name: 'log_sistem',
      displayName: 'Log Sistem',
      meta: {
        icon: 'article',
      },
    },
  ],
  // admin dinas
  2: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'pelatihan',
      displayName: 'Pelatihan',
      meta: {
        icon: 'mso-note_stack',
      },
    },
    {
      name: 'pengumuman',
      displayName: 'Pengumuman',
      meta: {
        icon: 'campaign',
      },
    },
  ],
  // admin ppkd
  3: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'pelatihan',
      displayName: 'Pelatihan',
      meta: {
        icon: 'mso-note_stack',
      },
    },
    {
      name: 'pengajar',
      displayName: 'Pengajar',
      meta: {
        icon: 'mso-cast_for_education',
      },
    },
    {
      name: 'pengumuman',
      displayName: 'Pengumuman',
      meta: {
        icon: 'campaign',
      },
    },
    {
      name: 'unggah_sertifikat',
      displayName: 'Unggah Sertifikat',
      meta: {
        icon: 'card_membership',
      },
    },
    {
      name: 'penempatan',
      displayName: 'Penempatan',
      meta: {
        icon: 'mso-location_away',
      },
    },
  ],
  // peserta
  4: [
    {
      name: 'pelatihan',
      displayName: 'Pelatihan',
      meta: {
        icon: 'mso-note_stack',
      },
    },
    {
      name: 'sertifikat',
      displayName: 'Sertifikat',
      meta: {
        icon: 'contact_emergency',
      },
    },
    {
      name: 'materi-peserta',
      displayName: 'Materi',
      meta: {
        icon: 'mso-history_edu',
      },
    },
  ],
  // pengajar
  5: [
    {
      name: 'materi',
      displayName: 'Materi',
      meta: {
        icon: 'mso-history_edu',
      },
    },
    {
      name: 'absensi',
      displayName: 'Absensi',
      meta: {
        icon: 'mso-person_check',
      },
    },
    {
      name: 'lpj',
      displayName: 'LPJ',
      meta: {
        icon: 'engineering',
      },
    },
    // {
    //   name: 'absensi',
    //   displayName: 'Absensi',
    //   meta: {
    //     icon: 'event_note',
    //   },
    // },
  ],
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: roleRoutes as INavigationRouteByRole,
}
